<template>
  <v-form v-model="formIsValid">
    <v-row dense ref="scheduleForm">
      <v-col cols="12">
        <v-checkbox v-model="scheduleImport" label="Programar importación" dense hide-details />
      </v-col>
      <v-col v-if="scheduleImport" cols="12" sm="5">
        <v-text-field
          v-model="formData.name"
          label="Nombre *"
          :rules="[$data.$globalRules.required, !!scheduleImport]"
          autofocus
        />
      </v-col>
      <v-col v-if="scheduleImport" cols="12" sm="5">
        <v-prd-date-picker-field
          v-model="date"
          label="Fecha importación"
          :min="today"
          :rules="[$data.$globalRules.required, !!scheduleImport]"
          @change="handleDate"
        />
      </v-col>

      <v-col v-if="scheduleImport" cols="12" sm="2">
        <v-text-field
          v-model="time"
          label="Hora *"
          type="time"
          :rules="[$data.$globalRules.required, !!scheduleImport]"
          @change="handleDate"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  components: {
    vPrdDatePickerField: () => import("@/components/v-prd/DatePickerTextField"),
  },
  props: {
    formData: { type: Object, required: true },
  },
  data: () => ({
    formIsValid: null,
    scheduleImport: null,
    date: null,
    time: null,
    today: null,
  }),
  beforeMount() {
    this.today = this.$moment().format("YYYY-MM-DD");
  },
  methods: {
    handleDate() {
      if (!this.time) this.time = "00:00";
      this.formData.scheduleDate = this.date + " " + this.time + ":00";
    },
  },
  watch: {
    formIsValid(newValue) {
      this.$emit("formChecked", newValue);
    },
  },
};
</script>

<style scoped></style>
